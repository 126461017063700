import React, { useEffect, useState } from 'react';
import mainLogoImg from '../../assets/images/home/footer-logo.png';
// import AdSenseAd from '../../components/Adsense/adSenseAd';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { API_URL, localStoragePrefix } from '../../utils/config';
import { manageVideoDetails } from '../../redux/actions/manageVideos';
import { checkAvailableVideoPath, checkServerURL } from '../../helper/helper';

const Video = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [videoDetails, setVideoDetails] = useState();
    const userID = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
    const authToken = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
    const itemPath = checkServerURL(API_URL, checkAvailableVideoPath(videoDetails?.path, videoDetails?.path_mp4, videoDetails?.modified_path));
    const itemThumbnail = videoDetails?.thumbnail && API_URL + videoDetails?.thumbnail;

    useEffect(() => {
        if (!id) return;
        const payload = {
            user_id: userID,
            authToken: authToken,
            id: id
        }
        dispatch(manageVideoDetails(payload, (type, res) => {
            if (type === 'success') {
                setVideoDetails(res);
            }
        }));
    }, [id]);

    return (
        <div className='quixy-share-page-wrapper' id="quixy-share-page-wrapper">
            <div className='quixy-header' id='quix-dashboard-header'>
                <div className='quix-dashboard-logo'>
                    <img src={mainLogoImg} alt="logo" />
                </div>
            </div>
            <div className='quix-share-page-content'>
                <div className='quix-share-page-content-inner'>
                    <div className='adsense-left-sidebar adsense-sidebar'>
                        {/* <AdSenseAd
                            client="ca-pub-4424794709901498"
                            slot="4629045189"
                            format="vertical"
                            style={{ display: 'block', minWidth: "100%", width: "100%", height: "100%" }}
                            responsive={true}
                        /> */}
                    </div>
                    <div className='quix-share-content'>
                        <div className='quix-share-item'>
                            <video
                                controls
                                src={`${itemPath}`}
                                poster={`${itemThumbnail}`}
                            >
                            </video>
                        </div>
                    </div>
                    <div className='adsense-right-sidebar adsense-sidebar'>
                        {/* <AdSenseAd
                            client="ca-pub-4424794709901498"
                            slot="8461586205"
                            format="vertical"
                            style={{ display: 'block', minWidth: "100%", width: "100%", height: "100%" }}
                            responsive={true}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;