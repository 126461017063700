import axios from "axios";
import {
    LOADING_USERS,
    GET_ALL_USERS,
    NOT_FOUND_ADMIN_USERS,
    GET_ADMIN_USER_BY_ID,
    GET_ALL_SUSPEND_USERS,
    UPDATE_USER_BY_ID,
    NOT_FOUND_SUSPEND_USERS,
    LOADING_SUSPEND_USERS,
    LOADING_PENDING_USERS,
    NOT_FOUND_PENDING_USERS,
    GET_ALL_PENDING_USERS,
} from "../../actions/type";
import { API_URL } from "../../../utils/config";

// get all users list
export const getAllUserListAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_USERS,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(`${API_URL}/admin/users`, data, config);
            if (res?.data?.status === true) {
                if (res?.data?.data?.length <= 0) {
                    dispatch({
                        type: NOT_FOUND_ADMIN_USERS,
                        payload: [],
                    });
                } else {
                    dispatch({
                        type: GET_ALL_USERS,
                        payload: res?.data,
                    });
                }
            }
        } catch (err) {
            console.log(err, "---Error---");
        }
    };
};

// get users by his id
export const getSuspendUserListAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_SUSPEND_USERS,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/users/suspend-user-list?status=2`,
                data,
                config
            );
            if (res?.data?.status === true) {
                if (res?.data?.data?.length <= 0) {
                    dispatch({
                        type: NOT_FOUND_SUSPEND_USERS,
                        payload: [],
                    });
                } else {
                    dispatch({
                        type: GET_ALL_SUSPEND_USERS,
                        payload: res?.data,
                    });
                }
            }
        } catch (err) {
            console.log(err, "---Error---");
        }
    };
};

// get users by his id
export const getPendingUserListAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_PENDING_USERS,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/users/pending-user-list?status=0`,
                data,
                config
            );
            if (res?.data?.status === true) {
                if (res?.data?.data?.length <= 0) {
                    dispatch({
                        type: NOT_FOUND_PENDING_USERS,
                        payload: [],
                    });
                } else {
                    dispatch({
                        type: GET_ALL_PENDING_USERS,
                        payload: res?.data,
                    });
                }
            }
        } catch (err) {
            console.log(err, "---Error---");
        }
    };
};

// get users by his id
export const getUserByIdAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_USERS,
            });

            let data = JSON.stringify({
                admin_id: payload?.adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/users/get-user-details/${payload?.id}`,
                data,
                config
            );
            if (res?.data?.status === true) {
                dispatch({
                    type: GET_ADMIN_USER_BY_ID,
                    payload: res?.data,
                });
                callBack("success", res?.data);
            }
        } catch (err) {
            callBack("failed", err);
            console.log(err, "---Error---");
        }
    };
};

// update user by his id
export const updateUserByIdAdmin = (payload, callBack) => {
    return async (dispatch) => {
        dispatch({
            type: LOADING_USERS,
        });
        try {
            let data = new FormData();
            if (payload?.name) {
                data.append("name", payload?.name);
            }
            if (payload?.email) {
                data.append("email", payload?.email);
            }
            if (payload?.picture) {
                data.append("picture", payload?.picture);
            } else {
                data.append("picture", null);
            }
            if (payload?.admin_id) {
                data.append("admin_id", payload?.admin_id);
            }
            if (payload?.id) {
                data.append("id", payload?.id);
            }
            if (payload?.role) {
                data.append("role", payload?.role);
            }
            if (payload?.status) {
                data.append("status", payload?.status);
            }

            let config = {
                maxBodyLength: Infinity,
            };

            const res = await axios.post(
                `${API_URL}/admin/users/update`,
                data,
                config
            );
            if (res?.data?.status === true) {
                dispatch({
                    type: UPDATE_USER_BY_ID,
                    payload: res?.data,
                });
                callBack("success", res?.data);
            }
        } catch (error) {
            let message = error?.response?.data;
            callBack("fail", message);
            console.log(error, "---Error---");
        }
    };
};

// update pricing plan user by id
export const updateUserPricingPlanByIdAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                "admin_id": payload?.admin_id,
                "user_id": payload?.user_id
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    'Content-Type': 'application/json'
                },
            };

            const res = await axios.post(`${API_URL}/admin/users/update-user-pricingplan/${payload?.id}`, data, config);
            if (res?.data?.status === true) {
                callBack("success", res?.data);
            }
        } catch (error) {
            let message = error?.response?.data;
            callBack("fail", message);
            console.log(error, "---Error---");
        }
    }
}

// search users by name
export const SearchUserAdmin = (payload) => {
    return async (dispatch) => {
        try {
            if (payload?.query === "suspendUser") {
                dispatch({
                    type: LOADING_SUSPEND_USERS,
                });
            } else if (payload?.query === "pendingUser") {
                dispatch({
                    type: LOADING_PENDING_USERS,
                });
            } else {
                dispatch({
                    type: LOADING_USERS,
                });
            }
            let data = JSON.stringify({
                admin_id: payload?.admin_id,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            let searchUrl = "";
            if (payload?.query === "suspendUser") {
                searchUrl += `${payload?.text}&status=2`;
            } else if (payload?.query === "pendingUser") {
                searchUrl += `${payload?.text}&status=0`;
            } else {
                searchUrl += `${payload?.text}`;
            }

            const res = await axios.post(
                `${API_URL}/admin/users/list?search=${searchUrl}`,
                data,
                config
            );
            if (res?.data?.status === true) {
                if (payload?.query === "suspendUser") {
                    if (res?.data?.data?.length <= 0) {
                        dispatch({
                            type: NOT_FOUND_SUSPEND_USERS,
                            payload: [],
                        });
                    } else {
                        dispatch({
                            type: GET_ALL_SUSPEND_USERS,
                            payload: res?.data,
                        });
                    }
                } else if (payload?.query === "pendingUser") {
                    if (res?.data?.data?.length <= 0) {
                        dispatch({
                            type: NOT_FOUND_PENDING_USERS,
                            payload: [],
                        });
                    } else {
                        dispatch({
                            type: GET_ALL_PENDING_USERS,
                            payload: res?.data,
                        });
                    }
                }
                else {
                    if (res?.data?.data?.length <= 0) {
                        dispatch({
                            type: NOT_FOUND_ADMIN_USERS,
                            payload: [],
                        });
                    } else {
                        dispatch({
                            type: GET_ALL_USERS,
                            payload: res?.data,
                        });
                    }
                }
            }
        } catch (error) {
            const message = error?.response?.data;
            // callBack("fail", message);
            console.log("---error---", error);
        }
    };
};

// delete user by his id
export const deleteUserByIdAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_USERS,
            });
            dispatch({
                type: LOADING_SUSPEND_USERS,
            });
            let data = JSON.stringify({
                admin_id: payload?.admin_id,
                id: payload?.id
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };

            const res = await axios.delete(
                `${API_URL}/admin/users/delete`,
                config
            );
            if (res?.data?.status === true) {
                callBack("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.data;
            callBack("fail", message);
            console.log("---error---", error);
        }
    };
};