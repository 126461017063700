import React, { useEffect, useState, useRef } from "react";
import logoImg from "../../../assets/images/home/logo.png";
import loadingIcon from "../../../assets/images/light-loader.gif";
import sucessMessageImg from "../../../assets/images/quix-success.png";
import failureImg from "../../../assets/images/quix-failure.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    resendVerifyEmailToken,
    verifyEmailToken,
} from "../../../redux/actions/authentication";
import { useDispatch } from "react-redux";
import {
    failureMessagePopup,
    successMessagePopup,
} from "../../../helper/helper";
import AuthChecker from "../../../helper/AuthChecker";

const VerifyEmail = () => {
    const [message, setMessage] = useState(null);
    const [messageStatus, setMessageStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const [hideMessageBox, setHideMessageBox] = useState(false);
    const searchToken = location?.search?.replace("?=", "");
    const requestSent = useRef(false);
    const navigate = useNavigate();

    const manageTokenVerification = (status) => {
        switch (status) {
            case 200:
                return setMessageStatus(200);
            case 300:
                return setMessageStatus(300);
            case 303:
                return setMessageStatus(303);
            default:
                return setMessageStatus(300);
        }
    };

    const handleSubmit = (payload) => {
        if (payload?.token !== "") {
            setLoading(true);
            setTimeout(() => {
                dispatch(
                    verifyEmailToken(payload, (type, res) => {
                        if (type === "success") {
                            const status = res?.status;
                            const message = res?.data?.message;
                            manageTokenVerification(status);
                            setMessage(message);
                            setLoading(false);
                        } else if (type === "failed") {
                            const status = res?.status;
                            const message = res?.data?.message;
                            manageTokenVerification(status);
                            setMessage(message);
                            setLoading(false);
                        }
                    })
                );
            }, 2000);
        }
    };

    useEffect(() => {
        if (!searchToken || requestSent.current) return;
        requestSent.current = true;

        const payload = {
            token: searchToken,
        };
        setTimeout(() => {
            handleSubmit(payload);
        }, 2000);
    }, [searchToken]);

    const resendToken = () => {
        setLoading(true);
        const payload = {
            token: searchToken,
        };
        setDisabled(true);
        dispatch(
            resendVerifyEmailToken(payload, (type, res) => {
                if (type === "success") {
                    setDisabled(false);
                    successMessagePopup("Success Message", res?.message);
                    setLoading(false);
                    setMessage(res?.message);
                    setHideMessageBox(true);
                } else if (type === "failed") {
                    setDisabled(false);
                    setLoading(false);
                    failureMessagePopup("Error Message", res?.message);
                    setHideMessageBox(true);
                }
            })
        );
    };

    const handleGoLoginPage = (e) => {
        navigate("/login");
    };

    return (
        <div className="login-page">
            <AuthChecker/>
            <div id="quix-dashboard-wrapper">
                <div id="quix-dashboard-wrapper">
                    <div id="quix-dashboard-inner">
                        <div className="login-logo-outer">
                            <div className="login-logo-inner">
                                <Link to={"/"}>
                                    <img src={logoImg} />
                                </Link>
                            </div>
                        </div>
                        <div className="login-form-outer">
                            {!messageStatus && (
                                <div className="login-form-inner">
                                    <span className="form-title">Verifying Your Account!</span>
                                    <span className="form-subtitle">Please wait...</span>
                                </div>
                            )}
                            {!loading && !hideMessageBox ? (
                                <>
                                    {messageStatus === 200 && (
                                        <>
                                            <div className="login-form-inner">
                                                <span className="form-title">Welcome to Vizam!</span>
                                                <span className="form-subtitle">Your account is now verified. Let’s get started!</span>
                                            </div>
                                            <div className="message-box card mb-2">
                                                <div className="card-body pb-0">
                                                    <img src={sucessMessageImg} /> <span>{message}</span>
                                                </div>
                                                <div className="success-modal-btns text-center pb-4">
                                                    <button
                                                        onClick={(e) => handleGoLoginPage(e)}
                                                        className={`btn`}
                                                    >
                                                        Login Here
                                                    </button>
                                                </div>
                                            </div>

                                        </>
                                    )}
                                    {messageStatus === 300 && (
                                        <>
                                            <div className="message-box card mb-4">
                                                <div className="card-body">
                                                    <img src={failureImg} /> <span>{message}</span>
                                                </div>
                                            </div>
                                            <div className="resend-request text-center">
                                                <div className="text-center mb-3">
                                                    <span className="resend-title">
                                                        Need a new verification token?
                                                    </span>
                                                    <span className="resend-text">
                                                        Please resend the token as previous one is expired.
                                                    </span>
                                                </div>
                                                <button
                                                    onClick={() => resendToken()}
                                                    className="btn token-resend-btn"
                                                    disabled={disabled}
                                                >
                                                    Resend Token
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {messageStatus === 303 && (
                                        <>
                                            <div className="message-box card mb-4">
                                                <div className="card-body">
                                                    <img src={failureImg} /> <span>{message}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : hideMessageBox ? (
                                <>
                                    <div className="message-box card mb-2">
                                        <div className="card-body pb-0">
                                            <img src={sucessMessageImg} /> <span>{message}</span>
                                        </div>
                                        <div className="success-modal-btns text-center pb-4">
                                            <button
                                                onClick={(e) => handleGoLoginPage(e)}
                                                className={`btn`}
                                            >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="text-center mb-5">
                                    <img src={loadingIcon} height={"40px"} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
