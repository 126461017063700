import React, { useState } from "react";
import { authLoginAdmin } from "../../../redux/actions/admin/authentication";
import { useDispatch } from "react-redux";
import { failureMessagePopup, redirectURL } from "../../../helper/helper";
import loadingIcon from "../../assets/images/light-loader.gif";
import showPassIcon from "../../assets/images/eye-pass-icon.png";
import hidePassIcon from "../../assets/images/hidden-pass-icon.png";
import AuthChecker from "../../../helper/AuthChecker";

const AdminLogin = () => {
    const [formData, setFormData] = useState({ email: "", password: "" });
    const [error, setError] = useState({ emailErr: "", passwordErr: "" });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
        if (!formData?.email && !formData?.password) {
            setError({
                emailErr: "Email is reqiured!",
                passwordErr: "Password is reqiured!",
            });
        } else if (!formData?.email) {
            setError({ emailErr: "Email is reqiured!" });
        } else if (!formData?.password) {
            setError({ passwordErr: "Password is reqiured!" });
        } else {
            setError({ emailErr: "", passwordErr: "" });
            setLoading(true);
            dispatch(
                authLoginAdmin(formData, (type, res) => {
                    if (type === "success") {
                        setLoading(false);
                        window.location.href = redirectURL();
                    } else if (type === "fail") {
                        setLoading(false);
                        failureMessagePopup("Error Message", res?.message);
                    }
                })
            );
        }
    };

    return (
        <div className="admin-login-wrapper">
            <AuthChecker />
            <div className="admin-login-page-content d-flex justify-content-center align-items-center my-5">
                <div className="quix-admin-login-form-outer">
                    <div className="card admin-login-card quix-form-outer">
                        <div className="card-header">
                            <div className="card-title m-0 py-2">
                                <h4 className="h-4 m-0">Welcome to Vizam!</h4>
                            </div>
                        </div>
                        <div className="card-body custom-admin-login-details">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>
                                <div className="quix-login-email">
                                    <input
                                        type="text"
                                        name="email"
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                        id="email"
                                        maxLength={255}
                                    />
                                </div>
                                {error.emailErr && (
                                    <span className="error-message text-danger form-text">
                                        {error.emailErr}
                                    </span>
                                )}
                            </div>
                            <div className="mb-3 user-password-filed">
                                <label htmlFor="password" className="form-label">
                                    Password
                                    <span className="text-danger ps-1">*</span>
                                </label>
                                <div className="password-eyes quix-login-password">
                                    <input
                                        type={!showPassword ? "password" : "text"}
                                        name="password"
                                        onChange={(e) => handleChange(e)}
                                        className="form-control"
                                        id="password"
                                        maxLength={255}
                                    />
                                    <img
                                        src={!showPassword ? hidePassIcon : showPassIcon}
                                        height={"100%"}
                                        width={"100%"}
                                        onClick={() => setShowPassword(!showPassword)}
                                    />
                                </div>
                                {error.passwordErr && (
                                    <span className="error-message text-danger form-text">
                                        {error.passwordErr}
                                    </span>
                                )}
                            </div>
                            <div className="quix-form-btn-outer py-2 d-flex justify-content-center">
                                <button
                                    className="quix-form-btn quix-submit-btn"
                                    onClick={() => handleSubmit()}
                                    disabled={loading}
                                >
                                    Login
                                </button>
                                <img
                                    style={{ display: !loading ? "none" : "block" }}
                                    className="btn-loader-icons"
                                    src={loadingIcon}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
