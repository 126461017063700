export const LOGIN = "LOGIN";
export const SIGN_UP = "SIGN_UP";
export const LOGOUT = "LOGOUT";
export const Get_USER_DETAIL_BY_ID = "Get_USER_DETAIL_BY_ID";
export const EDIT_USER_DETAIL = "EDIT_USER_DETAIL";
export const LOADING = "LOADING";
export const GET_PROGRESS = "GET_PROGRESS";
export const LOADING_SCREENSHORT = "LOADING_SCREENSHORT";
export const GET_SCREENSHOTS = "GET_SCREENSHOTS";
export const GET_STARRED_SCREENSHOTS = "GET_STARRED_SCREENSHOTS";
export const LOADING_STARRED_SCREENSHORT = "LOADING_STARRED_SCREENSHORT";
export const UPDATE_STARRED_SCREENSHOT = "UPDATE_STARRED_SCREENSHOT";
export const SCREENSHOT_DETAILS = "SCREENSHOT_DETAILS";
export const SHORT_SCREENSHOT = "SHORT_SCREENSHOT";
export const SHORT_STARRED_SCREENSHOT = "SHORT_STARRED_SCREENSHOT";
export const UPLOAD_SCREENSHOT = "UPLOAD_SCREENSHOT";
export const NOT_FOUND_SCREENSHOT = "NOT_FOUND_SCREENSHOT";
export const NOT_FOUND_STARRED_SCREENSHOT = "NOT_FOUND_STARRED_SCREENSHOT";
export const LOADING_STARRED_SCREENSHOT = "LOADING_STARRED_SCREENSHOT";
export const GET_VIDEOS = "GET_VIDEOS";
export const LOADING_VIDEOS = "LOADING_VIDEOS";
export const GET_STARRED_VIDEOS = "GET_STARRED_VIDEOS";
export const LOADING_STARRED_VIDEOS = "LOADING_STARRED_VIDEOS";
export const SHORT_VIDEOS = "SHORT_VIDEOS";
export const SHORT_STARRED_VIDEOS = "SHORT_STARRED_VIDEOS";
export const EDITED_VIDEO_DETAILS = "EDITED_VIDEO_DETAILS";
export const UPDATE_STARRED_VIDEO = "GET_STARRED_VIDEO";
export const NOT_FOUND_VIDEO = "NOT_FOUND_VIDEO";
export const NOT_FOUND_STARRED_VIDEO = "NOT_FOUND_STARRED_VIDEO";
export const RENAME_CARD = "RENAME_CARD";
export const SEND_BY_EMAIL = "SEND_BY_EMAIL";
export const REMOVE_CARD = "REMOVE_CARD";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const DOWNLOAD_VIDEO = "DOWNLOAD_VIDEO";
export const EDIT_VIDEO = "EDIT_VIDEO";
export const GET_ALL_MEMBERSHIP = "GET_ALL_MEMBERSHIP";
export const NOT_FOUND_MEMBERSHIP = "NOT_FOUND_MEMBERSHIP";
export const GET_ALL_PRICINGPLAN = "GET_ALL_PRICINGPLAN";
export const NOT_FOUND_PRICINGPLAN = "NOT_FOUND_PRICINGPLAN";
export const GET_PAYMENT = "GET_PAYMENT";
export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP";
export const UNSUBSCRIBE_MEMBERSHIP = "UNSUBSCRIBE_MEMBERSHIP";
export const TAG_LIST = "TAG_LIST";
export const ADD_TAG = "ADD_TAG";
export const SEARCH_VIDEOS = "SEARCH_VIDEOS"
export const SEARCH_STARRED_VIDEOS = "SEARCH_STARRED_VIDEOS"
export const SEARCH_SCREENSHOTS = "SEARCH_SCREENSHOTS"
export const SEARCH_STARRED_SCREENSHOTS = "SEARCH_STARRED_SCREENSHOTS"
// ADMIN
export const ADMIN_LOGIN = "ADMIN_LOGIN"
export const LOADING_USERS = "LOADING_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ADMIN_USER_BY_ID = "GET_ADMIN_USER_BY_ID";
export const NOT_FOUND_ADMIN_USERS = "NOT_FOUND_ADMIN_USERS";
export const LOADING_SUSPEND_USERS = "LOADING_SUSPEND_USERS";
export const GET_ALL_SUSPEND_USERS = "GET_ALL_SUSPEND_USERS";
export const NOT_FOUND_SUSPEND_USERS = "NOT_FOUND_SUSPEND_USERS";
export const LOADING_PENDING_USERS = "LOADING_PENDING_USERS";
export const GET_ALL_PENDING_USERS = "GET_ALL_PENDING_USERS";
export const NOT_FOUND_PENDING_USERS = "NOT_FOUND_PENDING_USERS";
export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID";
export const LOADING_ADMIN_SCREENSHORT = "LOADING_ADMIN_SCREENSHORT";
export const GET_ALL_ADMIN_SCREENSHOT = "GET_ALL_ADMIN_SCREENSHOT";
export const NOT_FOUND_ADMIN_SCREENSHOT = "NOT_FOUND_ADMIN_SCREENSHOT";
export const LOADING_ADMIN_VIDEO = "LOADING_ADMIN_VIDEO";
export const GET_ALL_ADMIN_VIDEO = "GET_ALL_ADMIN_VIDEO";
export const NOT_FOUND_ADMIN_VIDEO = "NOT_FOUND_ADMIN_VIDEO";
export const LOADING_ADMIN_MEMBERSHIP = "LOADING_ADMIN_MEMBERSHIP";
export const GET_ALL_ADMIN_MEMBERSHIP = "GET_ALL_ADMIN_MEMBERSHIP";
export const NOT_FOUND_ADMIN_MEMBERSHIP = "NOT_FOUND_ADMIN_MEMBERSHIP";
export const UPDATE_ADMIN_MEMBERSHIP = "UPDATE_ADMIN_MEMBERSHIP";
export const LOADING_ADMIN_PRICING_PLAN = "LOADING_ADMIN_PRICING_PLAN";
export const GET_ALL_ADMIN_PRICING_PLAN = "GET_ALL_ADMIN_PRICING_PLAN";
export const NOT_FOUND_ADMIN_PRICING_PLAN = "NOT_FOUND_ADMIN_PRICING_PLAN";
export const ADMIN_ADD_PRICING_PLAN = "ADMIN_ADD_PRICING_PLAN";
export const UPDATE_ADMIN_PRICING_PLAN = "UPDATE_ADMIN_PRICING_PLAN";
export const ADMIN_LOADING_TAGS = "ADMIN_LOADING_TAGS";
export const ADMIN_TAG_LIST = "ADMIN_TAG_LIST";
export const NOT_FOUND_ADMIN_TAG = "NOT_FOUND_ADMIN_TAG";
export const ADMIN_LOADING_USERS_TAGS = "ADMIN_LOADING_USERS_TAGS";
export const ADMIN_USERS_TAG_LIST = "ADMIN_USERS_TAG_LIST";
export const NOT_FOUND_ADMIN_USERS_TAG = "NOT_FOUND_ADMIN_USERS_TAG";
export const LOADING_STATISTICS = "LOADING_STATISTICS";
export const GET_ALL_STATISTICS = "GET_ALL_STATISTICS";
export const LOADING_EXTENSION = "LOADING_EXTENSION";
export const GET_EXTENSION_DETAILS = "GET_EXTENSION_DETAILS";
export const UPDATE_EXTENSION_SETTING = "UPDATE_EXTENSION_SETTING";
export const LOADING_GENERAL_SETTINGS = "LOADING_GENERAL_SETTINGS";
export const GET_GENERAL_SETTINGS_DETAILS = "GET_GENERAL_SETTINGS_DETAILS";
export const UPDATE_GENERAL_SETTINGS = "UPDATE_GENERAL_SETTINGS";