import React, { useState } from 'react';
import uploaderIcon from '../../assets/images/upload-icon.png';
import closeIcon from '../../assets/images/quix-close.png'
import { addWaterMark } from '../../redux/actions/manageScreenshots';
import { connect, useDispatch } from 'react-redux';
import { checkServerURL, failureMessagePopup, successMessagePopup } from '../../helper/helper';
import { useEffect } from 'react';
import { isEmptyObject } from 'jquery';
import { API_URL, localStoragePrefix } from "../../utils/config";
import { getUserDetailsByID } from '../../redux/actions/authentication';

const WaterMarkUpload = ({ showModal, closeModal, cardData }) => {
    let fileFormat = ["image/jpg", "image/jpeg", "image/png"];
    const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
    const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
    const dispatch = useDispatch();
    // Retrieve the last watermark setting from cardData and parse the relevant properties for screenshot and video positions and texts, if they exist.
    const waterMarkSetting = cardData?.waterMarkSetting && cardData?.waterMarkSetting?.[cardData?.waterMarkSetting.length - 1]
    const screenshotBorderPostObj = waterMarkSetting?.position_screenshot && JSON.parse(waterMarkSetting?.position_screenshot);
    const screenshotTextObj = waterMarkSetting?.text_screenshot && JSON.parse(waterMarkSetting?.text_screenshot)
    const videoBorderPostObj = waterMarkSetting?.position_videos && JSON.parse(waterMarkSetting?.position_videos);
    const videoTextObj = waterMarkSetting?.text_videos && JSON.parse(waterMarkSetting?.text_videos)

    const [formData, setFormData] = useState({
        image_screenshot: "",
        text_screenshot: {
            "fontSize": '36',
            "fontColor": "#000000",
            "text": ""
        },
        screenshot_transparency: 0.5,
        position_screenshot: "",
        screenshot_border_x: 0,
        screenshot_border_y: 0,
        image_videos: "",
        text_videos: {
            "fontSize": '36',
            "fontColor": "#000000",
            "text": ""
        },
        videos_transparency: 0.5,
        position_videos: "",
        video_border_x: 0,
        video_border_y: 0
    });

    const [previewImage, setPreViewImage] = useState(null);
    const [previewVideoImage, setPreViewVideoImage] = useState(null);

    // Update preview images and form data based on the watermark settings if they exist
    useEffect(() => {
        if (!isEmptyObject(waterMarkSetting)) {
            if (!waterMarkSetting?.image_videos || waterMarkSetting?.image_videos === null || waterMarkSetting?.image_videos === 'null') {
                setPreViewVideoImage(null);
            } else {
                setPreViewVideoImage(checkServerURL(API_URL, waterMarkSetting?.image_videos));
            }
            if (!waterMarkSetting?.image_screenshot || waterMarkSetting?.image_screenshot === null || waterMarkSetting?.image_screenshot === 'null') {
                setPreViewImage(null);
            } else {
                setPreViewImage(checkServerURL(API_URL, waterMarkSetting?.image_screenshot));
            }
            setFormData({
                image_screenshot: checkServerURL(API_URL, waterMarkSetting?.image_screenshot),
                text_screenshot: {
                    "fontSize": screenshotTextObj?.fontSize,
                    "fontColor": screenshotTextObj?.fontColor,
                    "text": screenshotTextObj?.text
                },
                screenshot_transparency: waterMarkSetting?.screenshot_transparency,
                position_screenshot: screenshotBorderPostObj?.position,
                screenshot_border_x: screenshotBorderPostObj?.x,
                screenshot_border_y: screenshotBorderPostObj?.y,
                image_videos: checkServerURL(API_URL, waterMarkSetting?.image_videos),
                text_videos: {
                    "fontSize": videoTextObj?.fontSize,
                    "fontColor": videoTextObj?.fontColor,
                    "text": videoTextObj?.text
                },
                videos_transparency: waterMarkSetting?.videos_transparency,
                position_videos: videoBorderPostObj?.position,
                video_border_x: videoBorderPostObj?.x,
                video_border_y: videoBorderPostObj?.y
            })
            if (screenshotBorderPostObj?.position === "C") {
                setScreenshotDisable(true);
            }
            if (videoBorderPostObj?.position === "C") {
                setVideosDisable(true);
            }
        }
    }, [waterMarkSetting]);

    // Handles the change event for watermark file uploads. It processes the selected file, creates a temporary URL for preview, 
    // and updates the form data with the selected screenshot or video file based on the specified type.
    const handleChangeWaterMark = async (e, type) => {
        let file = e.target.files?.[0];
        let fileType = file?.type;
        if (fileFormat.indexOf(fileType) > -1) {
            if (file) {
                let imgUrl = URL.createObjectURL(file);
                if (type === 'screenshot') {
                    setPreViewImage(imgUrl);
                    setFormData({ ...formData, image_screenshot: file });
                } else if (type === 'video') {
                    setPreViewVideoImage(imgUrl);
                    setFormData({ ...formData, image_videos: file });
                }
            }
        } else {
            failureMessagePopup(
                "Unsupported Image Format",
                "Please upload a JPG, JPEG or PNG file."
            );
        }
    }

    // Function to handle the form submission
    const handleSubmit = () => {
        const payload = {
            ...formData,
            user_id: userID,
            access_token: accessToken
        }
        // Dispatch the action to add a watermark
        dispatch(addWaterMark(payload, (type, res) => {
            if (type === "success") {
                closeModal(false);
                successMessagePopup("Success message", "Water mark added successfully!");
                // Verify user authentication with the provided access token
                dispatch(getUserDetailsByID(accessToken,()=>{}));
            } else if (type === "failed") {
                failureMessagePopup("Error message", res?.message);
            }
        }))
    }

    // for disabled input fileds
    const [screenshotDisable, setScreenshotDisable] = useState(false)
    const [videosDisable, setVideosDisable] = useState(false)
    // Function to handle changes in input fields
    const handleChange = (e, action) => {
        const { name, value } = e.target
        if (action === 'screenshot') {
            if (name === "position_screenshot" && value === "C") {
                // Disable the screenshot settings
                setScreenshotDisable(true);
                setFormData({
                    ...formData,
                    position_screenshot: value,
                    screenshot_border_x: "0",
                    screenshot_border_y: "0"
                })
            } else {
                // Enable screenshot settings if value is not "C"
                setScreenshotDisable(false)
                setFormData({
                    ...formData,
                    position_screenshot: value,
                    screenshot_border_x: screenshotBorderPostObj?.x,
                    screenshot_border_y: screenshotBorderPostObj?.y
                })
            }
        } else if (action === 'video') {
            if (name === "position_videos" && value === "C") {
                // Disable the video settings
                setVideosDisable(true)
                setFormData({
                    ...formData,
                    position_videos: value,
                    video_border_x: "0",
                    video_border_y: "0"
                })
            } else {
                // Enable video settings if value is not "C"
                setVideosDisable(false)
                setFormData({
                    ...formData,
                    position_videos: value,
                    video_border_x: videoBorderPostObj?.x,
                    video_border_y: videoBorderPostObj?.y
                })
            }
        }
    }
    // Function to handle the removal of watermarks
    const handleRemoveWatermark = (type) => {
        if (type === 'screenshot') {
            // Reset the preview image for screenshots
            setPreViewImage(null);
            setFormData({ ...formData, image_screenshot: null });
        } else if (type === 'video') {
            // Reset the preview image for videos
            setPreViewVideoImage(null);
            setFormData({ ...formData, image_videos: null });
        }
    }

    const [activeTab,setActiveTab] = useState('screenshot')

    return (
        <div className='water-mark-popup'>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    <div className="quix-dashboard-popup-title">
                        <div className="quix-dashboard-popup-title-inner">
                            <span>Upload Watermark</span>
                        </div>
                    </div>
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            <div className='quix-water-details quix-upload-outer text-center'>
                                {/* water mark tabs */}
                                <div className='watermakr-tabs-btn-outer'>
                                    <button id="wtrMrkScreenshotTab" onClick={()=>setActiveTab('screenshot')} className={`btn watermark-tab-btn ${activeTab === 'screenshot' ? 'active' : ''}`}>Screenshot</button>
                                    <button id="wtrMrkVideoTab" onClick={()=>setActiveTab('video')} className={`btn watermark-tab-btn ${activeTab === 'video' ? 'active' : ''}`}>Recording</button>
                                </div>
                                 {/* water mark tabs */}
                                <div className='water-mark-tabs-content'>
                                    <div className={`${activeTab === 'screenshot' ? 'active' : ''} watermark-tab-content quix-water-screenshot-content`}>
                                        {/* <p className='quix-heading-con'>Upload screenshot watermark</p> */}
                                        <div className="col-md-12 pt-4 mb-3 text-start">
                                            <div className={`quix-item-uploader-box ${previewImage ? 'd-flex gap-2' : ''}`}>
                                                {previewImage && (
                                                    <div className='quix-upoloaded-img'>
                                                        <img src={previewImage} />
                                                        <div className='quix-remove-img-watermark' onClick={() => handleRemoveWatermark('screenshot')}>
                                                            <img src={closeIcon} />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='quix-uploader'>
                                                    <div className="quix-item-uploader">
                                                        <input
                                                            id="input-file-upload"
                                                            className="quix-upload-input"
                                                            accept={fileFormat.join(",")}
                                                            type="file"
                                                            name="image_screenshot"
                                                            onChange={(e) => handleChangeWaterMark(e, 'screenshot')}
                                                            defaultValue={formData?.image_screenshot}
                                                        />
                                                        <img src={uploaderIcon} />
                                                    </div>
                                                    <span style={{ fontSize: "12px", display: 'block' }}>Choose only this format(JPG,JPEG,PNG)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-1 text-start">
                                            <label className="col-form-label">Water mark text</label>
                                            <input
                                                type="text"
                                                name="text_screenshot"
                                                className="form-control"
                                                onChange={(e) => {
                                                    setFormData({ ...formData, text_screenshot: { ...formData.text_screenshot, "text": e.target.value } })
                                                }}
                                                defaultValue={formData?.text_screenshot?.text}
                                                maxLength={255}
                                            ></input>
                                        </div>
                                        <div className="row mb-1 text-start">
                                            <div className='col-md-4'>
                                                <label className="col-form-label">Font Size</label>
                                                <input
                                                    type='text'
                                                    className="form-control"
                                                    name='fontSize'
                                                    onChange={(e) => setFormData({
                                                        ...formData, text_screenshot: {
                                                            ...formData.text_screenshot,
                                                            "fontSize": e?.target?.value
                                                        }
                                                    })}
                                                    value={formData?.text_screenshot?.fontSize}
                                                    maxLength={255}
                                                ></input>
                                            </div>

                                            <div className='col-md-4'>
                                                <label className="col-form-label">Text Color</label>
                                                <input type='color' className="form-control color-picker-box"
                                                    onChange={(e) => setFormData({
                                                        ...formData, text_screenshot: {
                                                            ...formData.text_screenshot,
                                                            "fontColor": e?.target?.value
                                                        }
                                                    })}
                                                    defaultValue={formData?.text_screenshot?.fontColor}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="col-form-label">Transparency</label>
                                                <input type='text' className="form-control"
                                                    name='transparency'
                                                    onChange={(e) => setFormData({ ...formData, screenshot_transparency: e.target.value })}
                                                    value={formData?.screenshot_transparency}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-1 text-start">
                                            <label className="col-form-label">Position</label>
                                            <select
                                                className='form-control form-select'
                                                onChange={(e) => {
                                                    setFormData({ ...formData, position_screenshot: e?.target?.value })
                                                    handleChange(e, 'screenshot')
                                                }}
                                                name='position_screenshot'
                                                defaultValue={formData?.position_screenshot}
                                            >
                                                <option value={'LT'} selected={formData?.position_screenshot === 'LT' ? true : false}>Left Top</option>
                                                <option value={'LB'} selected={formData?.position_screenshot === 'LB' ? true : false}>Left Bottom</option>
                                                <option value={'RT'} selected={formData?.position_screenshot === 'RT' ? true : false}>Right Top</option>
                                                <option value={'RB'} selected={formData?.position_screenshot === 'RB' ? true : false}>Right Bottom</option>
                                                <option value={'C'} selected={formData?.position_screenshot === 'C' ? true : false}>Center</option>
                                            </select>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6 mb-1 text-start">
                                                <label className="col-form-label">Margin from border X</label>
                                                <input
                                                    type="text"
                                                    name="marginBorder"
                                                    onChange={(e) => setFormData({ ...formData, screenshot_border_x: e?.target?.value })}
                                                    className="form-control"
                                                    value={formData?.screenshot_border_x}
                                                    disabled={screenshotDisable}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                            <div className="col-md-6 mb-1 text-start">
                                                <label className="col-form-label">Margin from border Y</label>
                                                <input
                                                    type="text"
                                                    name="marginBorder"
                                                    onChange={(e) => setFormData({ ...formData, screenshot_border_y: e?.target?.value })}
                                                    className="form-control"
                                                    value={formData?.screenshot_border_y}
                                                    disabled={screenshotDisable}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${activeTab === 'video' ? 'active' : ''} watermark-tab-content quix-water-video-content`}>
                                        {/* <p className='quix-heading-con'>Upload video watermark</p> */}
                                        <div className="col-md-12 pt-4 mb-3 text-start">
                                            <div className={`quix-item-uploader-box ${previewVideoImage ? 'd-flex gap-2' : ''}`}>
                                                {previewVideoImage && (
                                                    <div className='quix-upoloaded-img'>
                                                        <img src={previewVideoImage} />
                                                        <div className='quix-remove-img-watermark' onClick={() => handleRemoveWatermark('video')}>
                                                            <img src={closeIcon} />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className='quix-uploader'>
                                                    <div className="quix-item-uploader">
                                                        <input
                                                            id="input-file-upload"
                                                            className="quix-upload-input"
                                                            accept={fileFormat.join(",")}
                                                            type="file"
                                                            name="image_videos"
                                                            onChange={(e) => handleChangeWaterMark(e, 'video')}
                                                            defaultValue={formData?.image_videos}
                                                            maxLength={255}
                                                        />
                                                        <img src={uploaderIcon} />
                                                    </div>
                                                    <span style={{ fontSize: "12px", display: 'block' }}>Choose only this format(JPG,JPEG,PNG)</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-1 text-start">
                                            <label className="col-form-label">Water mark text</label>
                                            <input
                                                type="text"
                                                name="text_videos"
                                                className="form-control"
                                                onChange={(e) => setFormData({
                                                    ...formData, text_videos: {
                                                        ...formData?.text_videos,
                                                        "text": e.target.value
                                                    }
                                                })}
                                                defaultValue={formData?.text_videos?.text}
                                                maxLength={255}
                                            ></input>
                                        </div>
                                        <div className="row mb-1 text-start">
                                            <div className='col-md-4'>
                                                <label className="col-form-label">Font Size</label>
                                                <input type='text' className="form-control"
                                                    onChange={(e) => setFormData({
                                                        ...formData, text_videos: {
                                                            ...formData?.text_videos,
                                                            "fontSize": e.target.value
                                                        }
                                                    })}
                                                    value={formData?.text_videos?.fontSize}
                                                    maxLength={255}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="col-form-label">Text Color</label>
                                                <input type='color' className="form-control color-picker-box"
                                                    onChange={(e) => setFormData({
                                                        ...formData, text_videos: {
                                                            ...formData?.text_videos,
                                                            "fontColor": e.target.value
                                                        }
                                                    })}
                                                    defaultValue={formData.text_videos.fontColor}
                                                ></input>
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="col-form-label">Transparency</label>
                                                <input type='text' className="form-control"
                                                    name='transparency'
                                                    onChange={(e) => setFormData({ ...formData, videos_transparency: e.target.value })}
                                                    value={formData?.videos_transparency}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-1 text-start">
                                            <label className="col-form-label">Position</label>
                                            <select
                                                className='form-control form-select'
                                                onChange={(e) => {
                                                    setFormData({ ...formData, position_videos: e?.target?.value })
                                                    handleChange(e, 'video')
                                                }}
                                                name='position_videos'
                                            >
                                                <option value={'LT'} selected={formData?.position_videos === 'LT' ? true : false}>Left Top</option>
                                                <option value={'LB'} selected={formData?.position_videos === 'LB' ? true : false}>Left Bottom</option>
                                                <option value={'RT'} selected={formData?.position_videos === 'RT' ? true : false}>Right Top</option>
                                                <option value={'RB'} selected={formData?.position_videos === 'RB' ? true : false}>Right Bottom</option>
                                                <option value={'C'} selected={formData?.position_videos === 'C' ? true : false}>Center</option>
                                            </select>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6 mb-1 text-start">
                                                <label className="col-form-label">Margin from border X</label>
                                                <input
                                                    type="text"
                                                    name="marginBorder"
                                                    onChange={(e) => setFormData({ ...formData, video_border_x: e?.target?.value })}
                                                    className="form-control"
                                                    value={formData?.video_border_x}
                                                    disabled={videosDisable}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                            <div className="col-md-6 mb-1 text-start">
                                                <label className="col-form-label">Margin from border Y</label>
                                                <input
                                                    type="text"
                                                    name="marginBorder"
                                                    onChange={(e) => setFormData({ ...formData, video_border_y: e?.target?.value })}
                                                    className="form-control"
                                                    value={formData?.video_border_y}
                                                    disabled={videosDisable}
                                                    maxLength={255}
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="water-mark-edit-btn">
                                <button
                                    onClick={() => closeModal(false)}
                                    className="btn form-action-button form-cancel-button"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => handleSubmit()}
                                    className="btn form-action-button form-save-button"
                                >
                                    Save
                                </button>
                            </div>
                            <div
                                className="quix-dashboard-close"
                                onClick={() => closeModal(false)}
                            >
                                <img src={closeIcon} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state?.auth?.user_details,
});

export default connect(mapStateToProps, {})(WaterMarkUpload);